import { APIConfig } from "../constants/defaultValues";

/*
data:
    - url (API method name)
    - body (data request to API)
*/
export const requestAPIWithHeader = (method, data) => {
    
    const form_data = new FormData();
    for ( const key in data.body ) {
        form_data.append(key, data.body[key]);
    }

    const requestOptions = {
        method: method,
        headers: {  'Content-Type' : 'multipart/form-data; boundary=----WebKitFormBoundaryvagH2aITRfgQS8yx',
                    'Auth-Key': 'apikey',
                    'Client-Service': 'frontend-client',
                    'Authorization' : localStorage.getItem(APIConfig.APIPath.APIAuthKey) 
        },
        body: form_data
    };
    
    return fetch(APIConfig.APIPath + data.url, requestOptions)
        .then(res => res.json())
        .catch(err => err);

  };

/*
data:
    - url (API method name)
    - body (data request to API)
    - no Auth,Studentid in header
*/

  export function requestAPI (method, data) {

    const form_data = new FormData();
    for ( const key in data.body ) {
        form_data.append(key, data.body[key]);
    }

    const requestOptions = {
        method: method,
        headers: {  'Auth-Key': 'apikey',
                    'Client-Service': 'frontend-client',   
        },
        body: form_data,
    };

    return fetch(APIConfig.APIPath + data.url, requestOptions)
        .then(res => res.json())
        .catch(err => err);
    };


/*
data:
    - url (API method name)
    - header (data request to API) 
    - without body
*/
export const requestAPIInHeader = (method, data) => {

    const requestOptions = {
        method: method,
        headers: {  'Content-Type' : 'multipart/form-data; boundary=----WebKitFormBoundaryvagH2aITRfgQS8yx',
                    'Auth-Key': 'apikey',
                    'Client-Service': 'frontend-client',
                    'Authorization' : data.authkey,
                    'StudentID': data.studentid,      
        }
    };
         
    return fetch(APIConfig.APIPath + data.url, requestOptions)
        .then(res => res.json())
        .catch(err => err);

};


/*
data:
    - url (API method name)
    - header (data request to API) 
    - without body
*/
export const requestAPICourseID = (method, data) => {

    const requestOptions = {
        method: method,
        headers: {  
                    'Content-Type' : 'multipart/form-data; boundary=----WebKitFormBoundaryvagH2aITRfgQS8yx',
                    'Auth-Key': 'apikey',
                    'Client-Service': 'frontend-client',
                    'Authorization' : data.authkey,
                    'StudentID': data.studentid,   
                    'CourseID': data.courseid,      
        }
    };
         
    //return console.log(requestOptions);
    return fetch(APIConfig.APIPath + data.url, requestOptions)
        .then(res => res.json())
        .catch(err => err);

};

/*
data:
    - url (API method name)
    - header (data request to API) 
    - with body formdata
*/
export const requestAPIInHeaderwithBody = (method, data) => {
    const form_data = new FormData();
    for ( const key in data.body ) {
        form_data.append(key, data.body[key]);
    }

    const requestOptions = {
        method: method,
        headers: { 
                    'Auth-Key': 'apikey',
                    'Client-Service': 'frontend-client',
                    'Authorization' : data.authkey,
                    'StudentID': data.studentid,
        },
        body: form_data,
    };

    //return console.log(requestOptions);
     return fetch(APIConfig.APIPath + data.url, requestOptions)
        .then(res => res.json())
        .catch(err => err);

};


//LOGOUT FROM GOOGLE
//NOT USED YET
export function requestAPILogoutGmail (method, userkey) {

    const requestOptions = {
        method: 'POST',
        headers: {  'Auth-Key': 'apikey',
                    'Client-Service': 'frontend-client',         
        },
    };

    return fetch('https://admin.googleapis.com/admin/directory/v1/users/'+userkey+'/signOut', requestOptions)
        .then(res => res.json())
        .catch(err => err);
    };