import { all, call, delay, fork, put, takeEvery } from "redux-saga/effects";
import {
  requestAPI,
  requestAPIInHeader,
  requestAPIInHeaderwithBody,
} from "../../helpers/HttpRequest";
import {
  LOGIN_USER,
  LOGIN_WITHAUTHKEY,
  LOGIN_USER_WITH_GOOGLE,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  UPDATE_USER_PROFILE,
  GET_DATA_REQUEST,
  REQUEST_SCORE_REVIEW,
} from "../actions";

import {
  loginUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  logoutUserSuccess,
  getDataSuccess,
  getDataError,
  getRequestSuccess,
  getRequestFail,
} from "./actions";

// -- LOGIN
export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithIDPassword);
}

const loginWithIDPasswordAsync = async (method, data) =>
  await requestAPI(method, data);

function* loginWithIDPassword({ payload }) {
  const { method } = { method: "POST" };
  const data = {
    url: "login/login",
    body: payload.user,
  };

  const { history } = payload;

  try {
    const loginUser = yield call(loginWithIDPasswordAsync, method, data);

    if (loginUser.data.status !== 204) {
      localStorage.setItem("user_id", loginUser.data.studentid);
      localStorage.setItem("authkey", loginUser.data.token);
      localStorage.setItem("lastlogin", loginUser.data.lastlogin);
      localStorage.setItem("activeterm", loginUser.data.activeterm);
        history.push("/");
    } else {
      yield put(loginUserError(loginUser.data.message));
    }
  } catch (error) {
    yield put(loginUserError("Please check your connection"));
  }
}
// -- END LOGIN


// -- LOGIN from camed Mobile;
export function* watchLoginUserWithAuthkey() {
  yield takeEvery(LOGIN_WITHAUTHKEY, loginWithAuthkey);
}

const loginWithAuthkeyAsync = async (method, data) => {
  return await requestAPI(method, data);
};

function* loginWithAuthkey({ payload }) {
  const { method } = { method: "POST" };
  const data = {
    url: "login/loginwithauthkey",
    body: payload.user,
  };

  const { history } = payload;
  
  try {
    const loginUser = yield call(loginWithAuthkeyAsync, method, data);

    if (loginUser.data.status !== 204) {
      localStorage.setItem("user_id", loginUser.data.studentid);
      localStorage.setItem("authkey", loginUser.data.token);
      localStorage.setItem("lastlogin", loginUser.data.lastlogin);
      localStorage.setItem("activeterm", loginUser.data.activeterm);
      history.push("/"); // Redirecting to homepage after successful login
    } else {
      yield put(loginUserError(loginUser.data.message));
    }
  } catch (error) {
   yield put(loginUserError("Please check your connection"));
  }
}
// -- END LOGIN

// -- LOGIN WITH GOOGLE
export function* watchLoginUserwithGoogle() {
  yield takeEvery(LOGIN_USER_WITH_GOOGLE, loginWithGooglePassword);
}

const loginWithGooglePasswordAsync = async (method, data) =>
  await requestAPI(method, data);

function* loginWithGooglePassword({ payload }) {
  const { method } = { method: "POST" };
  const data = { url: "login/gmailsignin", body: payload.user };
  const { history } = payload;

  try {
    const loginUserWithGoogle = yield call(
      loginWithGooglePasswordAsync,
      method,
      data
    );
    if (loginUserWithGoogle.data.status !== 204) {
      localStorage.setItem("user_id", loginUserWithGoogle.data.studentid);
      localStorage.setItem("authkey", loginUserWithGoogle.data.token);
      history.push("/");
    } else {
      yield put(loginUserError(loginUserWithGoogle.data.message));
    }
  } catch (error) {
    yield put(loginUserError("Please check your connection"));
  }
}
// -- END LOGIN GOOGLE

// -- LOGOUT
export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

const logoutAsync = async (method, data) =>
  await requestAPIInHeader(method, data);

function* logoutUser({ payload }) {
  const { method } = { method: "POST" };
  const data = {
    url: "login/logout",
    studentid: localStorage.getItem("user_id"),
    authkey: localStorage.getItem("authkey"),
  };
  const { history } = payload;

  try {
    yield call(logoutAsync, method, data);
    history.push("/user/login");
    yield put(logoutUserSuccess("success"));
    localStorage.clear();
    window.location.reload(true);
  } catch (error) {}
}
// -- END LOGOUT

// -- GET STUDETNPROFILE
function* watchGetStudentProfile() {
  yield takeEvery(GET_DATA_REQUEST, getStudentProfile);
}

const GetStudentProfileAsync = async (method, data) =>
  await requestAPIInHeader(method, data);

// -- Create a generator function
function* getStudentProfile({ payload }) {
  const { method } = { method: "GET" };
  const data = {
    url: "student/studentprofile",
    studentid: localStorage.getItem("user_id"),
    authkey: localStorage.getItem("authkey"),
  };
  const { history } = payload;

  try {
    const UserProfile = yield call(GetStudentProfileAsync, method, data);

    if (UserProfile.status == 401) {
      yield put(loginUserError(UserProfile.message));
      localStorage.clear();
      history.push("/user/login");
    } else {
      const responesarr = UserProfile[0];
      yield put(getDataSuccess({ data: responesarr }));
    }
  } catch (error) {
    yield put(getDataError(error));
    yield delay(500);
    history.push("/user/login");
    localStorage.clear();
  }
}

// -- END STUDENTPROFILE

/*
 **  [UPDATE STUDENTPROFILE]
 **  AUTHURIZATION: StudentID / Authorization
 **  BODY: PHONE,EMAIL
 **  API: requestAPIInHeaderwithBody()
 */
export function* watchUpdateStudentProfile() {
  yield takeEvery(UPDATE_USER_PROFILE, updateStudentProfile);
}

const UpdateProfileAsync = async (method, data) =>
  await requestAPIInHeaderwithBody(method, data);

function* updateStudentProfile({ payload }) {
  const { method } = { method: "POST" };
  const data = {
    url: "student/studentprofile",
    studentid: localStorage.getItem("user_id"),
    authkey: localStorage.getItem("authkey"),
    body: payload.user,
  };
  const { history } = payload;

  try {
    const onUpdateProfile = yield call(UpdateProfileAsync, method, data);
    console.log(onUpdateProfile); return;
    if (onUpdateProfile.data.result === "success"){
      history.push("/app/pages/profile/editprofile");
    } else {
      yield put(loginUserError(onUpdateProfile.data.message));
        alert("Update Error");
    }
  } catch (error) {
    yield put(loginUserError("Please check your connection"));
  }
}
// -- END UPDATE STUDENTPROFILE

/*
 **  FORGET PASSWORD
 **  NO Auth,Studentid in header
 **  BODY: StudentID
 **  API: requestAPI()
 */
export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgetPasswordAsync = async (method, data) =>
  await requestAPI(method, data);

function* forgotPassword({ payload }) {
  const { method } = { method: "POST" };
  const data = { url: "login/resetpasswd", body: payload.forgotUserMail };

  const { history } = payload;
  try {
    const forgotPassword = yield call(forgetPasswordAsync, method, data);

    if (forgotPassword.data.status !== 401) {
      localStorage.setItem("message", forgotPassword.data.message);
      yield put(forgotPasswordSuccess("success"));
      history.push("/user/login");
    } else {
      yield put(forgotPasswordError(forgotPassword.data.message));
    }
  } catch (error) {
    yield put(forgotPasswordError("Please check your connection"));
    history.push("/user/login");
    localStorage.clear();
  }
}
// -- END FORGET PASSWORD

/*
 **  [RESET PASSWORD]
 **  AUTHURIZATION: StudentID / Authorization
 **  BODY: StudentID
 **  API: requestAPIInHeaderwithBody()
 */
export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (method, data) =>
  await requestAPIInHeaderwithBody(method, data);

function* resetPassword({ payload }) {
  const { method } = { method: "POST" };
  const data = {
    url: "student/changepasswd",
    studentid: localStorage.getItem("user_id"),
    authkey: localStorage.getItem("authkey"),
    body: payload,
  };
  const { history } = payload;

  try {
    const resetPasswordStatus = yield call(resetPasswordAsync, method, data);

    if (resetPasswordStatus.status !== 401) {
      if (resetPasswordStatus.data.status === 200) {
        //CHECK IF CHANGE SUCCESS
        yield put(resetPasswordSuccess("success"));
        history.push("/user/login");
        localStorage.clear();
      } else {
        yield put(resetPasswordError("Your Current Password Does not Match"));
      }
    } else {
      //ACCESS Unauthorized
      yield put(
        resetPasswordError(
          resetPasswordStatus.message + " Access Please login again!"
        )
      );
      history.push("/user/login");
    }
  } catch (error) {
    ///REQUEST FRM API FAIL
    yield put(resetPasswordError(error));
  }
}
// -- END RESET PASSWORD
export function* watchRequestscorereview() {
  yield takeEvery(REQUEST_SCORE_REVIEW, requestScoreReview);
}

const RequestScoreReviewAsync = async (method, data) =>
  await requestAPIInHeaderwithBody(method, data);

function* requestScoreReview({ payload }) {
  const { method } = { method: "POST" };
  const data = {
    url: "student/requestbascorereview",
    studentid: localStorage.getItem("user_id"),
    authkey: localStorage.getItem("authkey"),
    body: payload.data,
  };

  const { history } = payload;

  try {
    const onScorReveiwRequest = yield call(
      RequestScoreReviewAsync,
      method,
      data
    );
    if (onScorReveiwRequest.data.message == "Successfully Requested") {
      yield put(getRequestSuccess("success"));
    } else {
      yield put(getRequestFail(onScorReveiwRequest.data.message));
    }
  } catch (error) {
    yield put(loginUserError("Please check your connection"));
  }
}


export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLoginUserWithAuthkey),
    fork(watchLoginUserwithGoogle),
    fork(watchLogoutUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchGetStudentProfile),
    fork(watchUpdateStudentProfile),
    fork(watchRequestscorereview),
  ]);
}
