import {
  LOGIN_USER,
  LOGIN_WITHAUTHKEY,
  LOGIN_USER_WITH_GOOGLE,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGIN_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  USER_PROFILE,
  UPDATE_USER_PROFILE,
  GET_DATA_REQUEST,
  GET_DATA_SUCCESS,
  LOAD_USERS_ERROR,
  REQUEST_SCORE_REVIEW,
  GET_REQUEST_SUCCESS,
  GET_REQUEST_FAIL,


} from '../actions';

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history }
});

export const loginWithAuthkey = (user, history) => ({
  type: LOGIN_WITHAUTHKEY,
  payload: { user, history }
});

export const loginUserwithGoogle = (user, history) => ({
  type: LOGIN_USER_WITH_GOOGLE,
  payload: { user, history }
});

export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user
});

export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message }
});

export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history }
});

export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail
});

export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message }
});

export const resetPassword = ({currentpasswd,newpasswd,history}) => ({
  type: RESET_PASSWORD,
  payload: { currentpasswd,newpasswd,history }
});

export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword
});

export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message }
});

export const studentProfile = ( {auth_key,stuid,history} ) => ({
  type: USER_PROFILE,
  payload: { auth_key,stuid,history }
});

export const getDataRequest = ( history ) => ({
  type: GET_DATA_REQUEST,
  payload : { history }
});

export const updateProfile = ( user, history ) => ({
  type: UPDATE_USER_PROFILE,
  payload: { user , history }
});

export const getDataSuccess = ({ data }) => ({
  type: GET_DATA_SUCCESS,
  payload: { data }
});

export const getRequestSuccess = ({ message }) => ({
  type: GET_REQUEST_SUCCESS,
  payload: { message }
});

export const getRequestFail = ({ message })  => ({
  type: GET_REQUEST_FAIL,
  payload: { message }
});

export const getDataError = ({ message }) => ({
  type: LOAD_USERS_ERROR,
  payload: { message }
});

export const logoutUser = ( history) => ({
  type: LOGOUT_USER,
  payload: { history }
});

export const logoutUserSuccess = (message) => ({
  type: LOGOUT_USER_SUCCESS,
  payload: { message }
});

export const requestScoreReview = ( data, history ) => ({
  type: REQUEST_SCORE_REVIEW,
  payload: { data , history }
});